import _ from "lodash";
import { useFormEditorStore } from "../stores/formEditorStore";
import slugify from 'slugify';


const search = (rules, characteristic) => {
    if (rules?.length) {
        const rule = rules.find((value) => value.feature == characteristic);
        return rule;
    }
}

const evaluate = (rule, isNumeric = false) => {
    const formEditorStore = useFormEditorStore();
    let value = formEditorStore.getWidgetValueByName(rule.subject);
    if (isNumeric) {
        rule.value = _.isNumber(rule.value) ? rule.value : Number(rule.value.replace(',', '.'));
        if (value) {
            value = _.isNumber(value) ? value : (_.isString(value) ? Number(value.replace(',', '.')) : value[0]);
        }
    } else {
        let widget = formEditorStore.getWidgetByName(rule.subject);
        if (!widget) return widget;
        if (widget.classProperties.name == 'radio-group' || widget.classProperties.name == 'button-group') {
            rule.value = slugify(rule.value);
        }
    }
    return evaluateCalc(rule, value);
}
const evaluateLocal = (rule, _formDefIndex, data) => {
    const isNumeric = isNumericLocal(rule.subject, _formDefIndex);
    let value = getWidgetValueByNameLocal(rule.subject, _formDefIndex, data);
    if (isNumeric) {
        rule.value = _.isNumber(rule.value) ? rule.value : Number(rule.value.replace(',', '.'));
        if (value) {
            value = _.isNumber(value) ? value : (_.isString(value) ? Number(value.replace(',', '.')) : value[0]);
        }
    } else {
        let widget = getWidgetByNameLocal(rule.subject, _formDefIndex);
        if (!widget) return widget;
        if (widget.classProperties.name == 'radio-group' || widget.classProperties.name == 'button-group') {
            rule.value = slugify(rule.value);
        }
    }
    return evaluateCalc(rule, value);
}
const evaluateCalc = (rule, value) => {
    let result = false;

    switch (rule.operator) {
        case 'equal': {
            result = value == rule.value;
            break;
        }
        case 'not-equal': {
            result = value != rule.value;
            break;
        }
        case 'greater-than': {
            result = value > rule.value;
            break;
        }
        case 'greater-or-equal-than': {
            result = value >= rule.value;
            break;
        }
        case 'less-than': {
            result = value < rule.value;
            break;
        }
        case 'less-or-equal-than': {
            result = value <= rule.value;
            break;
        }
        case 'contains': {
            result = value && value.includes(rule.value);
            break;
        }
        case 'is-checked': {
            result = (value == true);
            break;
        }
        case 'is-not-checked': {
            result = (!value || (value == false));
            break;
        }
        case 'selected': {
            result = !!value;
            break;
        }
        case 'not-selected': {
            result = !(value);
            break;
        }
    }
    return result;
};

const isNumericLocal = (subject, _formDefIndex) => {
    const widget = _.find(_formDefIndex, function (o) {
        return o.instanceProperties.name == subject;
    });
    if (widget) {
        return widget.classProperties.name === 'input-number' || widget.classProperties.name === 'range';
    }
}
const getWidgetByNameLocal = (subject, _formDefIndex) => {
    const widget = _.find(_formDefIndex, function (o) {
        return o.instanceProperties.name == subject;
    });
    if (widget) {
        return widget;
    }
}
const getWidgetValueByNameLocal = (subject, _formDefIndex, data) => {
    const widget = _.find(_formDefIndex, function (o) {
        return o.instanceProperties.name == subject;
    });
    if (widget && data) {
        return data[subject];
    }
    return null;
}

export default {
    isVisible: (widget) => {
        const rule = search(widget.instanceProperties.rules, 'visibility');
        const formEditorStore = useFormEditorStore();
        if (rule) {
            if (formEditorStore.isNumericWidget(rule.subject)) {
                const r = evaluate(rule, true);
                if (r == undefined) {
                    return true;
                } else {
                    return r;
                }
            }
            const r = evaluate(rule);
            if (r == undefined) {
                return true;
            } else {
                return r;
            }
        }
        return true;
    },
    isVisibleLocal: (widget, _formDefIndex, data) => {
        const rule = search(widget.instanceProperties.rules, 'visibility');
        if (rule) {
            if (isNumericLocal(rule.subject, _formDefIndex)) {
                const r = evaluateLocal(rule, _formDefIndex, data);
                if (r == undefined) {
                    return true;
                } else {
                    return r;
                }
            }
            const r = evaluateLocal(rule, _formDefIndex, data);
            if (r == undefined) {
                return true;
            } else {
                return r;
            }
        }
        return true;
    },
    isDisabled: (widget) => {
        const rule = search(widget.instanceProperties.rules, 'disabled');
        const formEditorStore = useFormEditorStore();

        if (rule) {
            if (formEditorStore.isNumericWidget(rule.subject)) {
                const r = evaluate(rule, true);
                if (r == undefined) {
                    return false;
                } else {
                    return r;
                }
            }
            const r = evaluate(rule);
            if (r == undefined) {
                return false;
            } else {
                return r;
            }
        }
        return false;
    },
    isDisabledLocal: (widget, _formDefIndex, data) => {
        const rule = search(widget.instanceProperties.rules, 'disabled');

        if (rule) {
            if (isNumericLocal(rule.subject, _formDefIndex)) {
                const r = evaluateLocal(rule, _formDefIndex, data);
                if (r == undefined) {
                    return false;
                } else {
                    return r;
                }
            }
            const r = evaluateLocal(rule, _formDefIndex, data);
            if (r == undefined) {
                return false;
            } else {
                return r;
            }
        }
        return false;
    },
    isReadonly: (widget) => {
        const rule = search(widget.instanceProperties.rules, 'readonly');
        const formEditorStore = useFormEditorStore();
        if (rule) {
            if (formEditorStore.isNumericWidget(rule.subject)) {
                const r = evaluate(rule, true);
                if (r == undefined) {
                    return false;
                } else {
                    return r;
                }
            }
            const r = evaluate(rule);
            if (r == undefined) {
                return false;
            } else {
                return r;
            }
        }
        return false;
    },
    isRequired: (widget) => {
        const rule = search(widget.instanceProperties.rules, 'required');
        const formEditorStore = useFormEditorStore();
        if (rule) {
            if (formEditorStore.isNumericWidget(rule.subject)) {
                const r = evaluate(rule, true);
                if (r == undefined) {
                    return false;
                } else {
                    return r;
                }
            }
            const r = evaluate(rule);
            if (r == undefined) {
                return false;
            } else {
                return r;
            }
        }
        return false;
    },
    isRequiredLocal: (widget, _formDefIndex, data) => {
        const rule = search(widget.instanceProperties.rules, 'required');
        if (rule) {
            if (isNumericLocal(rule.subject, _formDefIndex)) {
                const r = evaluateLocal(rule, _formDefIndex, data);
                if (r == undefined) {
                    return false;
                } else {
                    return r;
                }
            }
            const r = evaluateLocal(rule, _formDefIndex, data);
            if (r == undefined) {
                return false;
            } else {
                return r;
            }
        }
        return false;
    },


};
