import { createRouter, createWebHistory } from 'vue-router';
import { useFormEditorStore } from '../stores/formEditorStore';
import { useTemplateStore } from '../stores/templateStore';

const routes = [
    {
        path: '/',
        component: () => import("../views/LoginView.vue"),
        // component: () => import('../views/FormEditorView.vue'),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("../views/ProfileView.vue"),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/DashboardView.vue'),
    },
    {
        path: '/report-editor/:template',
        name: 'report-editor',
        meta: {
            checkBack: true
        },
        component: () => import('../views/ReportEditorView.vue'),
    },
    {
        path: '/form-editor/:section',
        name: 'form-editor',
        meta: {
            checkBack: true
        },
        component: () => import('../views/FormEditorView.vue'),
    },
    {
        path: "/analyst",
        name: "analyst",
        component: () => import("../views/AnalystView.vue"),
    },
    {
        path: "/client",
        name: "client",
        component: () => import("../views/ClientView.vue"),
    },
    {
        path: "/processes",
        name: "processes",
        component: () => import("../views/ProcessesView.vue"),
    },
    {
        path: "/create-processes",
        name: "create-processes",
        component: () => import("../components/organism/CreateProcessing.vue"),
    },
    {
        path: "/processes-details/:id",
        name: "processes-details",
        component: () => import("../components/organism/ProcessingDetails.vue"),
    },
    {
        path: "/processes-edit/:id",
        name: "processes-edit",
        component: () => import("../components/organism/ProcessingEdit.vue"),
    },
    {
        path: "/processes-edit/:id/selected",
        name: "processes-edit-selected",
        component: () => import("../components/organism/ProcessingEditV2.vue"),
        meta: {
            stones: []
        },
    },
    {
        path: "/processes-edit/:id/stone/:stone_id",
        name: "/processes-edit-stone",
        component: () => import("../components/organism/ProcessingEdit.vue"),
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import("../views/ResetPasswordView.vue"),
    },
    {
        path: '/check-email',
        name: 'check-email',
        component: () => import("../views/CheckEmail.vue"),
    },
    {
        path: '/reset-password-link/',
        name: 'reset-password-link',
        component: () => import("../views/ChangePassword.vue"),

    },
    {
        path: '/success-password',
        name: 'success-password',
        component: () => import("../views/SuccessChangePassword.vue"),
    },
    {
        path: '/analyst-details/:id',
        name: 'analyst-details',
        component: () => import("../../js/components/organism/AnalystDetails.vue"),
    },
    {
        path: '/client-details/:id',
        name: 'client-details',
        component: () => import("../../js/components/organism/ClientDetails.vue"),
    },
    {
        path: '/section',
        name: 'section',
        component: () => import('../views/SectionView.vue'),
    },
    {
        path: '/type-analysis',
        name: 'type-analysis',
        component: () => import('../views/TypeAnalysisView.vue'),
    },
    {
        path: '/lots',
        name: 'lots',
        component: () => import('../views/LotsView.vue'),
    },
    {
        path: '/lot-details/:id',
        name: 'lot-details',
        component: () => import("../../js/components/organism/LotDetails.vue"),
    },
    {
        path: '/templates',
        name: 'templates',
        component: () => import("../views/TemplatesView.vue"),
    },
    {
        path: '/certificate-landing/:stone_id/:template_id',
        name: 'certificate-landing',
        component: () => import("../views/CertificateLanding.vue"),
    },
    {
        path: '/stones/archive',
        name: 'stones-archive',
        component: () => import("../views/StonesArchive.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

let navigationInfo = null
router.options.history.listen((to, from, info) => {
    // we cannot control navigation flow from here
    // just apply some side effects
    navigationInfo = info
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token");

    if (navigationInfo && from.meta?.checkBack) {
        console.log("TO:", to);
        console.log("FROM:", from);
        console.log("NAV:", navigationInfo);
        const formEditorStore = useFormEditorStore();
        if (formEditorStore.wasModified) {
            formEditorStore.suspendedNavDestination = to;
            formEditorStore.showFormNotSavedModal = true;
            console.log("NAV STOPPED");
            next(false);
        }
        const templateStore = useTemplateStore();
        if (templateStore.wasModified) {
            templateStore.suspendedNavDestination = to;
            templateStore.showTemplateNotSavedModal = true;
            console.log("NAV STOPPED");
            next(false);
        }
        // need to reset it to null
        navigationInfo = null
        next();
    } else if (to.name === "login") {
        if (token && token != "") {
            next({
                path: "/dashboard",
            });
        } else {
            next();
        }
    } else if (to.name === 'reset-password' || to.name === 'reset-password-link' || to.name === 'check-email') {
        console.log('Allowing access to reset-password or reset-password-link');
        next();
    } else if (to.path === 'reset-password-link') {
        console.log('Allowing access to reset-password or reset-password-link');
        next();
    }
    else {
        if (token && token != "") {
            next();
        } else {
            next({
                path: "/login",
            });
        }
    }
    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

export default router;
