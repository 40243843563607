import ApiService from '../resources/js/services/ApiService';

export default {
    create: (model) => {
        return ApiService.put(`/api/create-processing`, {
            data: {
                processing: model
            }
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("PROCESSING CREATE ERROR: ", error);
                return false;
            })
    },

    details(id) {
        return ApiService.get(`/api/processing/${id}`)
            .then((response) => {
                console.log('response', response.data);
                return response;
            })
            .catch((error) => {
                console.log("PROCESSING DETAIL ERROR: ", error);
                return false;
            });
    },
    detailsFromStone(stone_id) {
        return ApiService.get(`/api/processing/stone/${stone_id}`)
            .then((response) => {
                console.log('response', response.data);
                return response;
            })
            .catch((error) => {
                console.log("PROCESSING FROM STONE DETAIL ERROR: ", error);
                return false;
            });
    },
    count: () => {
        return ApiService.get(`/api/processing/count`)
            .then((response) => {
                console.log("processingService.count: ", response.data);
                return response.data.count;
            })
            .catch((error) => {
                console.log("GET PROCESSES ERROR: ", error);
                return false;
            });

    },
    search: (filters, sort, pagination, format) => {
        const payload = {
            "filter": filters,
            "sort": sort,
            "pagination": pagination,
            "format": format
        };
        Object.keys(payload).map(k => !payload[k] ? delete payload[k] : false);
        return ApiService.post(`/api/processing/search`, {
            data: payload
        }).then((response) => {
            console.log("key", response.data.key);
            return ApiService.get('/api/processing/search-results/' + response.data.key)
                .then((response) => {
                    console.log("processingService.search: ", response.data.pagination);
                    return {
                        "processings": response.data.processings,
                        "paginator": response.data.pagination
                    }
                });
        }).catch((error) => {
            console.log("GET PROCESSES ERROR: ", error);
            return false;
        });
    },

    delete(id) {
        return ApiService.delete(`/api/processing/${id}`)
            .then((response) => {
                console.log('response', response.data);
                return response;
            })
            .catch((error) => {
                console.log("PROCESSING DETAIL ERROR: ", error);
                return false;
            });
    },

    patchProcessing: (id, field, value) => {
        const payload = {
            "set": {
                "field": field,
                "value": value,
            }
        };
        return ApiService.patch(`/api/processing/${id}`, {
            data: payload
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log("PATCH PROCESSINGS NOTES ERROR: ", error);
                return false;
            })
    }
}
